<template>
  <v-container fluid>
    <div v-if="this.$route.name === 'schedules'">
      <v-btn class="ma-3" color="accent" small :to="`/survey/details/${id}/schedules/new-schedule`">
        <v-icon left dark>mdi-plus</v-icon>new Schedule
      </v-btn>
      <v-data-table
        :loading="loading"
        loading-text="Loading... Please wait"
        :headers="headers"
        :items="schedules"
        :search="search"
      >
        <template v-slot:item.action="{ item }">
          <v-icon small @click="deleteSchedule(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </div>
    <div v-else-if="this.$route.name ===`new-schedule`">
      <router-view />
    </div>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      search: null,
      schedules: [],
      headers: [
        { text: "Regions", value: "regionId" },
        { text: "Outlet types", value: "outletCat" },
        { text: "Start date", value: "start_date" },
        { text: "End date", value: "end_date" },
        { text: "", value: "action" }
      ]
    };
  },
  watch: {
    "$route.params.id": {
      handler: "setId",
      immediate: true
    }
  },
  methods: {
    getSchedules() {
      const self = this;
      this.loading = true;
      const id = this.$route.params.id;
      this.$store
        .dispatch("get", `/getschedules/${id}`)
        .then(res => {
          console.log(res, "r");
          self.schedules = res;
          self.loading = false;
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    deleteSchedule(data) {
      console.log(data);
    },
    setId(id) {
      this.id = id;
    }
  },
  created() {
    this.getSchedules();
  }
};
</script>